
@import '_variables.scss';
@import '_material.scss';

html,body {
  padding: 0 !important;
  margin: 0 !important;
  background: $background;
  font-family: $font;
  // Deals with IE rendering issues
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.carousel .slide {
  background: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 15px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 15px) !important;
}
