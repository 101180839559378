@import '../../styles/variables';

.navbarPages {
  background: $background;
  padding-top: 82px !important;
}

@media(min-width: 600px) {
  .navbarTextLinks {
    display: inline-block !important;
    margin-right: 5px;
  }
}

.navbarLink {
  color:#000000 !important;
  border-radius: 0px !important;
  text-transform: none !important;
  font-size: 1.0rem !important;
  font-weight: 300 !important;
  margin-left: 5px !important;
}

.lastNavbarLink {
  margin-right: 5px !important;
}

.accountLink {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.testing {
  display: flex;
  padding: 16px;
  align-items: center;
}

@media(max-width: $screen-sm) {
  .accountLink {
    margin-bottom: 10px !important;
  }
}

@media(max-width: $screen-sm) {
  .navbarLink {
    font-size: 1.0rem !important;
  }

  .navbarLink:last-of-type {
    margin-right: 5px !important;
  }
}

.navbarMenu {
  margin-top: 48px !important;
  visibility: visible;
}

.navbarDropIcon {
  margin-left: -4px !important;
  padding-right: 2px!important;
  margin-right: -8px!important;
}

.navbarSubLink {
  display: none !important;
}

.iconButton {
  margin-left: 5px !important;
  color: white !important;
}

@media(max-width: 600px) {
  .navbarLink {
    color: black !important;
    justify-content: left !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .navbarMenu {
    visibility: hidden !important;
  }

  .navbarDropIcon {
    visibility: hidden !important;
  }

  .navbarSubLink {
    padding-left: 25px !important;
    display: flex !important;
  }

  .navbarDropLink {
    cursor: none !important;
    pointer-events: none !important;
  }
}
