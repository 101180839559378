
.MuiStepper-root {
  padding: 12px !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
  border-bottom: 1px #ffffff42 solid;
}

.MuiFab-secondary {
  color: #efefef !important;
}

.MuiCardHeader-action {
  margin-top: 0px !important;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin: 0px !important;
}

.MuiDrawer-paperAnchorTop {
  top:83px !important;
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 100px !important;
  }
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 8px !important;
    padding-right: 8px !important
  }
}
