
@import '../../styles/_variables.scss';

.navbar {
  background: $white !important;
  box-shadow: none !important;
  border-bottom:  1px solid rgba(0, 0, 0, 0.12) !important;
  padding-right: 0px !important;
}

.logo {
  margin-top: 2px;
  margin-bottom: -2px;
  /* width: 180px; */
  height: 54px;
}

@media(min-width: 600px) {
  .logo:hover {
    cursor: pointer;
  }
}

.title {
  width: 200px;
  flex-grow: 1;
}

.toolbar {
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 54px !important;
}

.links > a {
  text-decoration: none;
  color: #000 !important;
  padding: 8px;
  font-size: 1rem;
}

@media(max-width: 738px) {
  .links {
    display: none !important;
  }
}

@media(min-width: 739px) {
  .mobileLinks {
    display: none !important;
  }
}

.menu {
  margin-right: -5px !important;
}

.drawerLinks {
  border-bottom: 1px solid #b7b7b7;
  display: grid;
}

.drawerLinks > a {
  text-decoration: none;
  color: black;
  padding: 12px;
  font-size: 1.1rem;
}
