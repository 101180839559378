

$black: #222123;
$white: #ffffff;

// Colours
$primary: #5e48c0;
$secondary: #687bff;
$background: #ffffff;
$offWhite: #f4f3f0;

// Fonts
$font: 'Blinker';
$backupFont: 'sans-serif';

// Breakpoints
$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1920px;
